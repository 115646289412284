import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const PayAdditionalPaymentActionButtonMaybe = props => {
  const {
    className,
    rootClassName,
    //showButtons,
    requestAdditionalPaymentInProgress,
    requestAdditionalPaymentError,
    //onRequestAdditionalPayment,

    transaction,
    onAcceptAndPay,
  } = props;

  const buttonDisabled = requestAdditionalPaymentInProgress;

  const requestAdditionalPaymentErrorMessage = requestAdditionalPaymentError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.requestFailed" />
    </p>
  ) : null;
  

  const classes = classNames(rootClassName || css.actionButtons, className);

//   const handleAcceptAndPay = () => {
//     this.state.pageData.transaction = transaction;
//     onAcceptAndPay();
//   }

  return  (
    <div className={classes}>
      <div className={css.actionErrors}>
        {requestAdditionalPaymentErrorMessage}        
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          inProgress={requestAdditionalPaymentInProgress}
          disabled={buttonDisabled}
          onClick={onAcceptAndPay}
        >
          <FormattedMessage id="TransactionPanel.payAdditionalPaymentButton" />
        </PrimaryButton>
        {/* <PrimaryButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptSale}
        >
          <FormattedMessage id="TransactionPanel.acceptButton" />
        </PrimaryButton> */}
      </div>
    </div>
  );
};

export default PayAdditionalPaymentActionButtonMaybe;
