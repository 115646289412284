import React from 'react';
import logo from './goldLogoHoriz.png';
import mobileLogo from './mobileLogo.png';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (
      <img
        className={className}
        src={logo}
      />
    );
  }

  return (
    <img
    className={className}
    src={mobileLogo}
  />
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
