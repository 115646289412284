import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const RequestAdditionalPaymentActionButtonMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    requestAdditionalPaymentInProgress,
    requestAdditionalPaymentError,
    onRequestAdditionalPayment,

    onAcceptAndPay,
    role
  } = props;

  const buttonDisabled = requestAdditionalPaymentInProgress;

  const requestAdditionalPaymentErrorMessage = requestAdditionalPaymentError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.requestFailed" />
    </p>
  ) : null;
  

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {requestAdditionalPaymentErrorMessage}        
      </div>
      <div className={css.actionButtonWrapper}>
        <SecondaryButton
          inProgress={requestAdditionalPaymentInProgress}
          disabled={buttonDisabled}
          onClick={onRequestAdditionalPayment}
        >
          <FormattedMessage id="TransactionPanel.requestAdditionalPaymentButton" />
        </SecondaryButton>
        {/* <PrimaryButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptSale}
        >
          <FormattedMessage id="TransactionPanel.acceptButton" />
        </PrimaryButton> */}
      </div>
    </div>
  ) : null;
};

export default RequestAdditionalPaymentActionButtonMaybe;
