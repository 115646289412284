import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Last updated: November 22, 2019</p> */}
      <h3>***COVID-19 Update</h3>
      <p className={css.text}>
      The charter party must be certain they have followed safe practices of social distancing
for at least the last 4 weeks, and are in good health. If for any reason the chartering
party is uncertain they are in good health, they must notify the captain. A temperature
scan may be required prior to boarding the vessel.
      </p>

      <h2>Your Relationship with BUKU</h2>
      <p className={css.text}>
      Your use of BUKU's products, services, software, and Website(s) (collectively as
"Services") is subject to the terms of this Terms of Use Agreement (this "Agreement").
BUKU means BUKU, Inc d/b/a BUKU and BUKU (herein referred to as "BUKU," "us,"
"we," "our," and the "Website"). If you are using the Services of BUKU on behalf of a
corporation or other legal entity, "you" means the entity, and therefore the user is
warranting that he or she has the rights to bind the entity in this agreement. By using the
Services of BUKU, you are agreeing to and are subject to the following terms and
conditions, as well as our company’s Privacy Policy, herein incorporated by reference
(collectively, the "Terms"). If you do not agree to all of the Terms in this agreement, then
you are NOT authorized to use BUKU's Services.
      </p>
      <p className={css.text}>You will accept the Terms by: (i) clicking to accept or agree to the Terms, where this
option is made available to you by BUKU in the user interface for any Service; or, (ii) by
actually using the Services of BUKU. In the case of (ii), you understand and agree that
BUKU treats your use of the Services as acceptance of the Terms from that point and
onward.</p>
      <p className={css.text}>BUKU also reserves the right, at its discretion, to modify, to change, add or remove any
of the Terms, in part, or in whole, at any time. Please check this Website and the Terms
periodically for changes and updates. Your use of the Services after such modified
Terms are posted will mean that you accept such Terms in this agreement.</p>
      <p className={css.text}>BUKU IS A VENUE AND IS NOT A PARTY NOR A PARTNER TO ANY RENTAL
AGREEMENT OR OTHER TRANSACTIONS BETWEEN ANY USERS OF THE
WEBSITE.</p>
      <p className={css.text}>BUKU is Only a Venue. BUKU acts as a venue for users of the Website as renters
("Renters") and boat owners ("Members") (Members and Renters, each a "User" and
together, "Users") to view and post available boats to facilitate the rental of such boats.
BUKU is not a party to any actual agreement or transaction between Renters and
Members even though we may from time to time provide tools that relate to a booking,
such as a tool to enable a Renter to enter into a transaction to rent a specific boat
directly from a Member and payment services to facilitate the booking. As a result,
BUKU has no formal control over the accuracy or correctness of the content or
information provided or used by such Renters and Members. Any part of an actual or
potential transaction between a Renter and a Member, including the condition, quality,
safety or legality of the boats advertised, the truth or accuracy of the listings (including
the content thereof or any review related thereto), the ability of Members to rent a boat
to a Renter, or the ability of Renters to pay for or operate boat rentals are solely the
responsibility of each User. With this agreement, Member acknowledges and agrees
that Member is solely responsible for the compliance and safety of the Renter(s) who
rent Member’s boat(s).
</p>
      <p className={css.text}>You acknowledge and agree to take all reasonable precautions in ensuring the accuracy
and reliability of any User generated content. We are also not responsible for the
condition of any boats listed on the Website or the compliance with laws, rules or
regulations that may be applicable to boat operation, maintenance, rental or charter in
any jurisdiction. As between BUKU and Members, Member acknowledges and agrees
that Member is solely responsible for ensuring compliance with laws, rules or
regulations that may be applicable to boat operation, maintenance, rental or charter in
the jurisdiction in which Member operates or is otherwise located. Renters should
satisfy themselves that any boat rented complies with any legal or safety requirements
for such rental, and Renters and Members have exclusive responsibility for such
compliance. Without limiting the foregoing, Members and Renters are solely responsible
for their own compliance with any legal and/or safety requirements in place in your
applicable jurisdiction in light of applicable health and/or travel advisories. Members are
responsible for determining if renting a boat is permissible and, if so, for providing their
boat in sanitary condition for Renters and with appropriate sanitation supplies for
Renters to clean the boat after use. Renters are responsible for returning any rented
boat in sanitary condition using the sanitation supplies provided by the Member.
</p>
      <p className={css.text}>Separate Agreements. Users acknowledge and agree that you will be required to enter
into a separate agreement and/or waiver, directly between the Renter and Member,
prior to making a booking or purchasing a product or service and such agreement
and/or waiver may place additional restrictions on your booking, product, or service. Any
separate agreements and/or waivers relating to the rental of a boat or related products
or services are under the sole control of any Member or provider and Renter.
</p>
      <p className={css.text}>Release and Indemnity. IN THE EVENT OF A DISPUTE BETWEEN A RENTER AND A
MEMBER, MEMBER AND RENTER, JOINTLY AND SEVERALLY, HEREBY AGREE TO
INDEMNIFY, RELEASE, DEFEND AND HOLD HARMLESS BUKU AND ITS
DIRECTORS, OFFICERS, AGENTS AND EMPLOYEES (COLLECTIVELY, THE “BUKU
PARTIES”) FROM ANY CLAIMS, CAUSES OF ACTION, DEMANDS, RECOVERIES,
LOSSES, DAMAGES (ACTUAL AND CONSEQUENTIAL), FINES, PENALTIES AND
OTHER COSTS OR EXPENSES, OF ANY KIND OR NATURE, INCLUDING BUT NOT
LIMITED TO REASONABLE LEGAL AND ACCOUNTING FEES (COLLECTIVELY, THE
“CLAIMS”), KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY
CONNECTED WITH SUCH DISPUTES, INCLUDING, WITHOUT LIMITATION,
DISPUTES ARISING OVER BREACH OF THIS AGREEMENT, BREACH OF ANY
SEPARATE AGREEMENT OR TRANSACTION, AND/OR THE SUBSTITUTION OF
RENTAL UNITS. FURTHER, MEMBER AND RENTER EACH HEREBY AGREE TO
INDEMNIFY, RELEASE, DEFEND AND HOLD HARMLESS THE BUKU PARTIES
FROM ANY CLAIMS BROUGHT AS A RESULT OF MEMBER AND/OR RENTER’S (1)
BREACH OF THE TERMS, (2) VIOLATION OF ANY LAW OR THE RIGHTS OF A
THIRD PARTY, AND (3) USE OF THIS WEBSITE AND THE SERVICES.</p>
      <p className={css.text}>Insurance. BUKU is not a party to any contract for insurance facilitated through any link
to a third party insurance provider advertising on the Website, and is not acting on
behalf of any insurer, any broker or agent in any way, any insured or any claimant in any
contract for insurance facilitated through such link. Insurance coverage must be
provided through a third-party independent insurance producer not affiliated with BUKU.
BUKU does not act as a broker, agent or consultant in the sale of insurance, is not
endorsing or recommending any particular insurer, policy or terms of coverage, and is
merely providing a quick link for you, if you wish, to transact a contract for insurance
with a third-party provider. Any contract for insurance is solely between you, as the
insured, and such insurer as you and the third-party insurance provider agree shall act
as the insurer per your selection. BUKU has no control over any insurance and makes
no representations regarding the coverage of any insurance you may purchase from a
third-party insurance provider. You understand, acknowledge and agree that BUKU
assumes no responsibility or liability for any claims or disputes arising from, or related
to, a contract for insurance facilitated through such link. You also acknowledge and
agree that BUKU shall not be included as a party to any such claims or disputes.</p>


      <h2>Your Use of Services</h2>
      <p className={css.text}>
      Conditions of Use. As a condition of your use of the Services, you expressly represent
and warrant that (i) if you will be operating a boat, you are responsible for ensuring that
you are sufficiency skilled in the operation of the boat and legally authorized to operate
a boat in the state in which you are renting and you will not allow any other occupants of
the boat to operate the boat unless they are each of sufficient skill and legally
authorized to operate a boat in the state in which you are renting; (ii) you possess the
legal authority to create a binding legal obligation; (iii) you will use this Website in
accordance with the Terms; (iv) you will only use this Website to post or view, as
applicable, available boat rentals or transact legitimate boat rentals for you or for
another person for whom you are legally authorized to act with other Users; (v) you will
inform such other persons about the terms and conditions that apply to the boat rental
you have made on their behalf, including all laws, regulations, rules and restrictions
applicable thereto; (vi) if you are booking a rental on behalf of another person, you are
individually responsible for ensuring that such other person is a party to the separate
agreement for the rental of the boat; (vii) all information supplied by you to BUKU is
true, accurate, current and complete, (viii) if you have a BUKU account, you will
safeguard your account information, (ix) you are and will remain in compliance with all
applicable laws, rules and regulations related to boat operation, maintenance, rental or
charter, and (x) if you are listing a boat for rental, you are either (a) the owner of the
boat, or (b) a representative authorized to act on behalf of the owner of the boat. You
may not authorize others to use your user account, and you may not assign or
otherwise transfer your user account to any other person or entity. If you reside in a
jurisdiction that restricts the use of the Services because of age, or restricts the ability to
enter into agreements such as this one due to age, you must abide by such age limits
and you must not use the Services. Without limiting the foregoing, the Services are not
available to children (persons under the age of 18). By using the Services, you
represent and warrant that you are at least 18 years old. BUKU retains the right at its
sole discretion to deny access to the Services to anyone at any time and for any reason,
including, but not limited to, for violation of the Terms.
      </p>
      <p className={css.text}>You agree that you will only use the Services for lawful purposes.</p>
      <p className={css.text}>Please keep in mind that we will treat anyone who uses your user name and password
as "you." We will provide this user with all of the rights and privileges that we provide to
you, and we will hold you responsible for the activities of the person using your
password. Therefore, we recommend that you maintain your user name and password
in confidence, and that you refrain from disclosing this information to anyone who might
"pretend" to be you with respect to the Services. We also ask that you notify us
immediately if you suspect that someone is using your user name and/or password or
otherwise obtaining access to the Services in this or any other inappropriate manner.</p>
      <p className={css.text}>Booking Fee. BUKU charges a booking fee of 10% of the total rental for every vessel
rented on our website. This is a service charge for the use of our site.</p>
      <p className={css.text}>Payment Services. While BUKU is not a party to any agreement between you and any
Member, Renter acknowledges and agrees that BUKU may act as the Member's
payment agent for the purposes of collecting payment for bookings made through the
BUKU Website. Renter's provision of financial information to us for purposes of payment
is governed by our Privacy Policy.</p>
      <p className={css.text}>Establishing a Payment Account. As a Renter, you are responsible for opening an
account, providing payment authorizations for bookings and paying for Services
according to these Terms, as well as others which may be included at the time of
booking. You agree to provide all requested information, including a credit card to be
used for pre-authorization/reserve and payment/capture for Services. Payment for a
booking will be made by BUKU directly to the Member who posted the listing.</p>
      <p className={css.text}>By providing your credit card information to us, you agree that we are authorized to
charge your card with the total cost of the rental and any required deposit, as described
below. We will run a pre-authorization for the full amount of the booking on the credit
card the Renter supplies at the time of booking. You agree that we can charge a
nominal amount to your credit card to verify the validity of the credit card information
you provide. If the Member accepts the booking, the Renter's credit card will be charged
in full. You expressly authorize BUKU's approved service providers, Paypal or CashApp,
to originate credit transfers to your financial institution account. BUKU may also use a
service provider to store your credit card information to facilitate payments as described
herein and you expressly authorize such storage. BUKU shall ensure that any service
provider it uses to process or store your credit card information is bound by obligations
to protect such information commensurate with our Privacy Policy.</p>
      <p className={css.text}>Deposits. Renters are responsible for leaving the rented boat in the same condition it
was in before you rented it. Renters acknowledge and agree that you are responsible
for all acts and omissions of you and your guests on the boat that affect the condition of
the boat. In the event a Member claims and provides evidence of damage to the boat,
you agree that BUKU shall be entitled to charge a deposit to your credit card to cover
the cost of the damage, as established after reasonable investigation. We will notify you
of any damage claim made by a Member related to your rental and you will have an
opportunity to respond, as set forth in such notice. We will only charge a deposit to
Renter's credit card after the rental is complete if the Member has made a claim and the
deposit amount has been deemed appropriate after an investigation of the claim.</p>
      <p className={css.text}>Service Charge. We charge a service fee of 10% of the total rental (i.e., the booking and
any additional expenses) for facilitating the rental and this amount will be reflected in the
final amount charged to Renter's credit card. We reserve the right to round up amounts
to the nearest whole dollar (for example, $302.60 will be rounded to $303). We will
process any necessary currency conversion (BUKU charges in US dollars unless
otherwise specified) based upon Renter's currency on the day the credit card is charged
and we will charge a conversion fee of 3% of the total rental for currency conversions.
We reserve the right to adjust the conversion rate based upon when the payout is made
to the Member. The service fee and any conversion fee constitute "Transaction Fees."
BUKU reserves the right to adjust its Transaction Fees at any time. Any Fee changes
will be effective the next time you book a rental after such fee change goes into effect
(i.e., rentals already booked will be subject to Fees as established at the time of
booking).</p>
      <p className={css.text}>Boat prices will vary, depending on the size and type of vessel. Prices are per hour, per
couple(2) for sailboats and power boats. There is a cost of $20 per person for each
additional guest. There is a 5 guest maximum. Advance Bookings(2 or more weeks)
require a 30% deposit of the total cost to be paid at the time of booking. The remaining
balance of the Booking must be paid in full before boarding the vessel. For Bookings
made within 7 days or less, a 50% deposit is required at the time of booking. The
remaining balance of the Booking must be paid in full before boarding the vessel.</p>
      <p className={css.text}>*The deposit includes the guest portion of the Buku booking fee. *Rates may vary for
Weekend, Holiday, Theme cruises and Special event dates. Additional fees may
include: Taxes, fuel expense, docking charges, fees associated with overnight stays
onboard and Liability/damage or theft charges (see Damage/theft liability). Some fees
only apply for certain boats, and will be outlined in the boat's profile section. Some boats
offer special amenities services, add ons, and themed items, in order to provide
customized experiences. If the guest chooses custom services, the additional cost will
be added to the balance due. If custom services or expenses are added during the
booked period, the services will be added to the final bill at booking completion. </p>
      <p className={css.text}>Payments to Members. Payment to Members for accepted bookings will be made net
30 days from the date of the completed reservation. Notwithstanding, in limited
circumstances, on a case-by-case basis, in its sole discretion, BUKU may elect to pay a
Member a portion of the accepted booking after the reservation is made and the
remaining portion of the accepted booking net 30 days from the date of the completed
(fully paid) reservation. If, after Member has been paid any portion of the accepted
booking by BUKU, an accepted booking is canceled or terminated for any reason by
either Member or Renter before the reservation is completed, Member shall return to
BUKU all sums advanced by BUKU for such reservation immediately (within five (5)
days) upon receiving written demand for same. Member further agrees that BUKU is not
liable for any damages Member may incur as a result of such cancellation and/or having
to return such funds. If a Member fails to timely refund said sums, BUKU shall also be
entitled to interest on said sums at the rate of 18% per annum, or the highest rate
allowed by law, whichever is lower, and all of BUKU’s attorneys’ fees and costs of court.</p>
      <p className={css.text}>If a Member does not respond to a booking inquiry, BUKU reserves the right to transfer
the booking and associated payment to another Member on Renter’s behalf. BUKU
reserves the right to hold payment to Members if BUKU suspects there has been fraud
or other suspicious activity in connection with the rental or payment. Unacceptable
payment methods that may be posted on the Website may result in the immediate
removal of the non-conforming listing from the Website without notice to the Member
and without refund. Unacceptable payment methods are those methods that may pose
a risk of fraud, identify theft, or breach of privacy.
</p>
      <p className={css.text}>Substitution of Rental Units. Renter understands and acknowledges that boats/other
watercraft reserved may, from time to time, be subsequently damaged or otherwise
become in need of repair before the reservation is commenced. If a Member is unable
to provide the reserved unit to Renter due to it being unavailable, Member may provide
Renter with a substitute unit that is similar in age, size, model, and value as the
reserved boat/other watercraft. Member shall provide Renter with notice of the need for
a substitution of unit(s) as soon as reasonably possible, as well as provide Renter with
pictures and a description of the substitute unit upon request. If Member is unable to
provide Renter with a similar substitute unit (as described herein), Renter may elect to
cancel the reservation any time before it is commenced and, as its sole remedy, obtain
a refund in accordance with the Refund Policy as if the Member had cancelled the
reservation. Renter shall not be entitled to any refund, and shall be obligated to pay all
sums owed, if Member offers Renter a similar unit (as described herein) and Renter
nevertheless refuses to complete and/or terminates the reservation. If there is a dispute
between Renter and Member as to whether the substitute unit offered by Member is
sufficiently similar to the unit reserved by Renter, BUKU shall make the final
determination, in its sole discretion, and Renter and Member agree to abide by BUKU’s
determination.</p>
      <p className={css.text}>ANY DIRECT OR INDIRECT EXCHANGE OF MONEY BETWEEN RENTERS AND
MEMBERS OTHER THAN PAYMENT THROUGH BUKU IS A VIOLATION OF THESE
TERMS.</p>
      <p className={css.text}>Taxes. The rental/booking fee and any additional expenses may be subject to applicable
local, federal and state taxes. The Member accepting the booking is solely responsible
for the collection, reporting, and payment of applicable taxes. BUKU may charge
applicable taxes to Renter's credit card solely at the direction of the Member. Members
are solely responsible for determining applicable taxes and should consult with your
own legal/tax adviser about any tax questions in this regard.</p>


      <h2>Cancellation and Refund Policy</h2>
      <p className={css.text}>
      If a Member cancels an accepted rental before the rental is commenced, Renter shall
be refunded all sums paid to BUKU for the rental, other than the Transaction Fee.
      </p>
      <p className={css.text}>If Renter cancels an accepted rental before the rental is commenced, BUKU shall return
to Renter a “Cancellation Refund,” as specified below. The Transaction Fee will not be
refunded to Renter under any circumstances. The amount of the Cancellation Refund is
determined by the Member’s cancellation policy set-forth in the Renter’s separate rental
agreement with the Member, which shall be one of the following three policies, as
chosen by the Member:</p>
      <p className={css.text}>Flexible</p>
      <p className={css.text}>● 100% of the rental price if cancelled more than 24 hours prior to
commencement of the rental; <br/>
● 50% of the rental price if cancelled within 24 hours of commencement
of the rental.</p>
      <p className={css.text}>Moderate</p>
      <p >● 100% of the rental price if cancelled more than 7 days prior to
commencement of the rental;<br/>● 75% of the rental price if cancelled less than 7 days but more than 48
hours prior to commencement of the rental; <br/>
● 50% of the rental price if cancelled less than 48 hours but more 24
hours prior to commencement of the rental.</p>
      <p className={css.text}>Strict</p>
      <p >● 75% of the rental price if cancelled more than 7 days prior to
commencement of the rental;<br/>
● 50% of the rental price if cancelled less than 7 days but more than 48
hours prior to commencement of the rental;<br/>
● 25% of the rental price if cancelled less than 48 hours but more 24
hours prior to commencement of the rental.</p>
      <p className={css.text}>If, for any reason, the Renter’s separate rental agreement with the Member (a) does not
contain a cancellation policy or (b) the cancellation policy therein differs from the
foregoing cancellation policy options, then the amount of the Cancellation Refund will be
determined in accordance with the “Strict” cancellation policy set-forth above. Renters
are further subject to any additional cancellation terms set forth in the terms of each
listing. To the extent the cancellation terms of a particular listing conflicts with the
cancellation terms set forth herein, the cancellation terms set forth herein control.</p>
      <p className={css.text}>If BUKU issues a Cancellation Refund to Renter that is less than 100% of the rental
price, Members will be credited with the non-refunded portion of such rental price on the
Member’s next rental that is successfully completed through BUKU.</p>
      <p className={css.text}>Owner Cancellation</p>
      <p className={css.text}>Transaction Fees will never be refunded. BUKU will charge a fee for any reservation
that is cancelled after both parties have agreed on a booking. The following is a
breakdown of cancellation fees based on the total price of rental:</p>
      <p className={css.text}>Under $100: Fee is $5</p>
      <p className={css.text}>Over $100 but below $250: Fee is $20</p>
      <p className={css.text}>Over $250: Fee is $50</p>
      <p className={css.text}>The Member will be debited the cancellation fees on the next rental that is successfully
completed through BUKU. Renters are subject to the cancellation terms set forth in the
terms of each listing. Additionally, if a Member cancels a rental for any reason other
than weather or another circumstance affecting the safety of the Renter, such Member
(rather than the Renter) will be charged the Transaction Fee for the rental, as set forth
above.
</p>
      <p className={css.text}>Chargeback Policy. If Renter disagrees with any charges, before any dispute is lodged
with Renter’s credit card company, Renter shall provide BUKU with a detailed written
explanation of such disputed sum within fourteen (14) days of the charge, and the
parties shall engage in a discussion related to same. FAILURE TO PROVIDE TIMELY
NOTICE OF SAID DISPUTE VOIDS ANY CLAIM OR DISPUTE. At BUKU’s request,
Renter shall provide any additional written explanation or details and/or supporting
documentation, in the format desired by BUKU. RENTER SHALL NOT DISPUTE VALID
CHARGES ASSESSED IN ACCORDANCE WITH THESE TERMS OF USE.
</p>
      <p className={css.text}>Member understands and acknowledges that BUKU acts merely as a conduit for
reservations and payments between Renters and Members, and Member assumes all
rental payment risk. IF A RENTER OBTAINS A CREDIT CARD CHARGEBACK
AGAINST BUKU, FOR ANY REASON, AND BUKU HAS ALREADY PAID ALL OR A
PORTION OF SUCH AMOUNT TO MEMBER, THEN MEMBER SHALL IMMEDIATELY
(WITHIN FIVE (5) DAYS UPON RECEIVING WRITTEN DEMAND FOR SAME)
RETURN TO BUKU ALL SUCH AMOUNTS.</p>
      <p className={css.text}>Use of Website. The content and information on BUKU's Website (this "Website")
(including, but not limited to, price and availability of boats listed in any inventory
database), as well as the infrastructure used to provide such content and information, is
proprietary to BUKU, its licensors, its suppliers and providers. While you may make
limited copies of your booking documents (and related documents) for boat rentals
booked through this Website, you agree not to and you have no right to otherwise
modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create
derivative works from, transfer, or sell or re-sell any information, software, content,
products, or services obtained from or through this Website. Additionally, you agree not
to:
</p>
      <p className={css.text}>●use this Website or its contents for any commercial purpose without
our express written permission;<br/>
●send or store infringing, obscene, threatening, libelous, or otherwise
unlawful or tortious material, including material harmful to children or
violative of third party privacy rights;<br/>
●send or store material containing software viruses, worms, Trojan
horses or other harmful computer code, files, scripts, agents or
programs;<br/>
●interfere with or disrupt the integrity or performance of the Services or
the data contained therein;<br/>
●attempt to gain unauthorized access to the Services or its related
systems or networks;<br/>
●make any speculative, false, or fraudulent reservation or any
reservation in anticipation of demand;<br/>
●access, monitor or copy any content or information of this Website
using any robot, spider, scraper or other automated means or any
manual process for any purpose without our express written
permission;<br/>
●violate the restrictions in any robot exclusion headers on this Website
or bypass or circumvent other measures employed to prevent or limit
access to this Website;<br/>
●take any action that imposes, or may impose, in BUKU's discretion,
an unreasonable or disproportionately large load on BUKU's
infrastructure;<br/>
●deep-link to any portion of this Website (including, without limitation,
the purchase path for any boat rental reservation) for any purpose
without our express written permission; or<br/>
●"frame", "mirror" or otherwise incorporate any part of this Website into
any other Website without our prior written authorization.
</p>
      <p className={css.text}>IMPORTANT: PLEASE REVIEW THE ARBITRATION AGREEMENT SET FORTH
BELOW CAREFULLY, AS IT WILL REQUIRE YOU TO RESOLVE DISPUTES WITH
BUKU AND OTHER PARTIES ON AN INDIVIDUAL BASIS THROUGH FINAL AND
BINDING ARBITRATION. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY
ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS
OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER THE
CONSEQUENCES OF THIS IMPORTANT DECISION.
</p>  
      <p className={css.text}>By agreeing to the Terms, you agree that you are required to resolve any claim that you
may have against BUKU and any other related party on an individual basis in
arbitration, as set forth below. This will preclude you from bringing any class, collective,
or representative action against BUKU, and also preclude you from participating in or
recovering relief under any current or future class, collective, consolidated, or
representative action brought against BUKU by someone else.
</p>  
      <p className={css.text}>Opt-out of Agreement to Arbitrate. You can decline this agreement to arbitrate by
emailing support@BUKU.comand saying “I opt out of arbitration” in the email within 30
days of first registering your account.</p>  
      <p className={css.text}>You and BUKU agree that any dispute, claim or controversy arising out of or relating to
this agreement whether between you and BUKU or between you and other Users, or
the existence, breach, termination, enforcement, interpretation or validity thereof,
(including the validity of this arbitration clause) shall be settled by binding arbitration
administered by FairClaims (www.FairClaims.com) and not in a court of law, in
accordance with its Arbitration Rules & Procedures effective at the time a claim is made.
You further agree that judgment on the award rendered by the arbitrator(s) may be
entered in any court having jurisdiction thereof.
</p>  
      <p className={css.text}>You consent to electronic service of process, with service to be made to the email
address we have on record for your account.</p>  
      <p className={css.text}>You acknowledge and agree that you and BUKU are each waiving the right to a trial by
jury or to participate as a plaintiff or class member in any purported class action or
representative proceeding. Unless both you and BUKU otherwise agree in writing, any
arbitration will be conducted only on an individual basis and not in a class, collective,
consolidated, or representative proceeding. However, you and BUKU each retain the
right to seek injunctive or other equitable relief in a court of competent jurisdiction to
prevent the actual or threatened infringement, misappropriation or violation of a party's
copyrights, trademarks, trade secrets, patents or other intellectual property rights.
</p>  
      <p className={css.text}>You agree that any and all communications and evidence related to any dispute
ultimately resolved by arbitration or mediation with FairClaims arising out of or relating
to this agreement shall be held and will remain confidential, and that you will not take
any action that will harm the reputation of any of the other parties to the arbitration or
mediation or BUKU or which would reasonably be expected to lead to unwanted or
unfavorable publicity to and of the parties, BUKU or other entities involved in or
incidental to the arbitration or mediation. You understand that those parties and entities
include but are not limited to the claimant, respondent, witnesses, and BUKU.</p>  
      <p className={css.text}>You agree that, in the event of confirmation and enforcement, the delinquent party to the
arbitration will be responsible for any attorney, court or other fees associated with such
action.</p>  
      <p className={css.text}>Notwithstanding any choice of law or other provision in this agreement, parties agree
and acknowledge that this arbitration clause evidences a transaction involving interstate
commerce and that the Federal Arbitration Act, 9 U.S.C. § 1 et seq. (“FAA”), will govern
its interpretation and enforcement and proceedings pursuant thereto. It is the intent of
the parties that the FAA and FairClaims Rules shall preempt all state laws to the fullest
extent permitted by law. If the FAA and FairClaims Rules are found to not apply to any
issue that arises under this arbitration clause or the enforcement thereof, then that issue
shall be resolved under the laws of the state of California.
</p>  
      <h2>Identity Verification</h2>
      <p className={css.text}>User verification on the Internet is difficult and we cannot, and do not, assume any
responsibility for the confirmation of each Renter or Member's purported identity.
Notwithstanding the foregoing, you agree that you will provide us or the Member with
whom you book a rental whatever proof of identity we may reasonably request.  </p>  
      <p className={css.text}>You agree to (i) keep your password and online ID for both your BUKU account and
your email account secure and strictly confidential, (ii) notify us immediately and select
a new online ID and password if you believe your password for either your BUKU
account or your email account may have become known to an unauthorized person,
and (iii) notify us immediately if you are contacted by anyone requesting your online ID
and password. We discourage you from giving anyone access to your online ID and
password for your BUKU account and your email account. However, if you do give
someone your online ID and online password, or if you fail to adequately safeguard
such information, you are responsible for any and all transactions that the person
performs while using your BUKU account or your email account, even those
transactions that are fraudulent or that you did not intend or want performed.
</p>  
      <h2>Exclusion of Warranties</h2>
      <p className={css.text}>YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE SERVICES ARE
PROVIDED "AS IS" AND "AS AVAILABLE" AND WITHOUT WARRANTY OF ANY KIND
AND BUKU ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUBSIDIARIES,
AFFILIATES, LICENSORS AND SUPPLIERS EXPRESSLY DISCLAIM ALL
WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE
IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT, AND
FITNESS FOR A PARTICULAR PURPOSE. NEITHER BUKU NOR ITS OFFICERS,
DIRECTORS, EMPLOYEES, AGENTS, SUBSIDIARIES, AFFILIATES, LICENSORS
NOR SUPPLIERS WARRANT THAT THE OPERATION OF THE SERVICE WILL BE
UNINTERRUPTED, ERROR FREE OR WITHOUT BREACHES OF SECURITY.</p>  
      <p className={css.text}>BUKU, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUBSIDIARIES,
AFFILIATES, AND ITS LICENSORS DO NOT REPRESENT OR WARRANT TO
RENTERS OR MEMBERS THAT:</p>  
      <p className={css.text}>● THE INFORMATION PROVIDED BY RENTERS OR MEMBERS IS
TRUE OR CORRECT,<br/>
●BOAT RENTALS WILL BE AVAILABLE AT ADVERTISED DATES,
TIMES, LOCATIONS, AND/OR PRICES, INCLUDING PURSUANT
TO A CONFIRMED OR PURCHASED RESERVATION,<br/>
● BOATS WILL BE COMPLIANT WITH ANY LEGAL OR SAFETY
REQUIREMENTS,<br/>
● YOUR USE OF THE SERVICES WILL MEET YOUR
REQUIREMENTS OR EXPECTATIONS,<br/>
● YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED,
TIMELY, SECURE OR FREE FROM ERROR,<br/>
● ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR
USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, OR<br/>
● THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF
ANY SOFTWARE PROVIDED TO YOU AS PART OF THE
SERVICES WILL BE CORRECTED.
</p>  
      <p className={css.text}>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
IMPLIED WARRANTIES, SO IN THESE JURISDICTIONS THE ABOVE LIMITATIONS
OR EXCLUSIONS MAY NOT APPLY.</p>  
      <h2>Limitation of Liability</h2>
      <p className={css.text}>IN NO EVENT WILL BUKU ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
SUBSIDIARIES OR AFFILIATES BE LIABLE TO RENTERS, MEMBERS, OR ANY
PARTY FOR:</p>  
      <p className={css.text}>● ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
SPECIAL, EXEMPLARY, OR PUNITIVE, DAMAGES ARISING OUT
OF THE USE, AVAILABILITY OR UNAVAILABILITY OF THE
SERVICES (INCLUDING BUT NOT LIMITED TO THE
UNAVAILABILITY OR CHANGE IN ADVERTISED AND/OR
PURCHASED BOAT RENTALS) OR ANY OTHER SERVICES OR
GOODS OBTAINED THROUGH USE OF ANY OF THE
FOREGOING, OR ANY DATA TRANSMITTED THROUGH THE
SERVICE, EVEN IF BUKU HAS BEEN ADVISED OF THE
POSSIBILITY OF SUCH DAMAGES; AND
</p>  
      <p className={css.text1}>○ ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY
YOU, INCLUDING BUT NOT LIMITED TO LOSS OR
DAMAGE AS A RESULT OF:<br/>
○ ANY RELIANCE PLACED BY YOU ON THE
COMPLETENESS, ACCURACY OR EXISTENCE OF ANY
ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP
OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER
OR SPONSOR WHOSE ADVERTISING APPEARS ON THE
SERVICES;<br/>
○ ANY CHANGES WHICH BUKU MAY MAKE TO THE
SERVICES, OR FOR ANY PERMANENT OR TEMPORARY
CESSATION IN THE PROVISION OF THE SERVICES (OR
ANY FEATURES WITHIN THE SERVICES);<br/>
○ YOUR FAILURE TO PROVIDE BUKU WITH ACCURATE
ACCOUNT INFORMATION;<br/>
○ YOUR FAILURE TO KEEP YOUR PASSWORD OR
ACCOUNT DETAILS SECURE AND CONFIDENTIAL.</p>  
      <p className={css.text}>● ANY BODILY INJURY, DEATH, OR PROPERTY DAMAGE THAT
MAY OCCUR AS THE RESULT OF A BOATING RELATED
ACCIDENT, THE RESERVATION FOR WHICH WAS FACILITATED
USING BUKU'S SERVICES. YOU AGREE THAT BUKU IS NOT A
PARTY TO ANY CONTRACT OR AGREEMENT FOR BOAT
RENTALS AND IS IN NO WAY RESPONSIBLE FOR INJURIES TO
PERSONS OR DAMAGE TO PROPERTY WHICH MAY OCCUR
DURING OR RELATED TO A BOAT RENTAL WHETHER AS A
RESULT OF THE NEGLIGENCE OF ANY RENTER, BOAT
OPERATOR, OR MEMBER, OR THE SAFETY OF ANY BOAT OR
FOR ANY OTHER REASON. YOU ACKNOWLEDGE AND AGREE
THAT BUKU SHALL HAVE NO LIABILITY RELATED TO YOUR
BOOKING AND RENTAL OF A BOAT, AND ANY SUCH BOOKING
AND/OR RENTAL IS MADE AT THE RENTER’S AND MEMBER’S
SOLE RISK.
</p>  
      <p className={css.text}>Notwithstanding the Terms, if BUKU is held liable, BUKU's entire aggregate liability
under or arising out of these Terms or the Services shall be limited to the greater of (a)
the service fees you paid to BUKU in connection with your use of this Website (if any),
or (b) One-Hundred Dollars (US$100.00). Some jurisdictions do not allow the exclusion
or limitation of certain damages, so in these jurisdictions the above limitations or
exclusions may not apply.
</p>  
      <h2>Release and Indemnification</h2>
      <p className={css.text}>IN THE EVENT OF A DISPUTE BETWEEN A RENTER AND A MEMBER, MEMBER
AND RENTER, JOINTLY AND SEVERALLY, HEREBY AGREE TO INDEMNIFY,
RELEASE, DEFEND AND HOLD HARMLESS BUKU AND ITS DIRECTORS,
OFFICERS, AGENTS AND EMPLOYEES (COLLECTIVELY, THE “BUKU PARTIES”)
FROM ANY CLAIMS, CAUSES OF ACTION, DEMANDS, RECOVERIES, LOSSES,
DAMAGES (ACTUAL AND CONSEQUENTIAL), FINES, PENALTIES AND OTHER
COSTS OR EXPENSES, OF ANY KIND OR NATURE, INCLUDING BUT NOT LIMITED
TO REASONABLE LEGAL AND ACCOUNTING FEES (COLLECTIVELY, THE
“CLAIMS”), KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY
CONNECTED WITH SUCH DISPUTES, INCLUDING, WITHOUT LIMITATION,
DISPUTES ARISING OVER BREACH OF THIS AGREEMENT, BREACH OF ANY
SEPARATE AGREEMENT OR TRANSACTION, AND/OR THE SUBSTITUTION OF
RENTAL UNITS. FURTHER, MEMBER AND RENTER EACH HEREBY AGREE TO
INDEMNIFY, RELEASE, DEFEND AND HOLD HARMLESS THE BUKU PARTIES
FROM ANY CLAIMS BROUGHT AS A RESULT OF MEMBER AND/OR RENTER’S (1)
BREACH OF THE TERMS, (2) VIOLATION OF ANY LAW OR THE RIGHTS OF A
THIRD PARTY, AND (3) USE OF THIS WEBSITE AND THE SERVICES.</p> 
      <h2>Proprietary Materials</h2>
      <p className={css.text}>The Services contain proprietary materials such as logos, text, software, photos, video,
graphics, music and sound. BUKU is the owner or licensee of all such proprietary
material and all intellectual property rights therein, including without limitation,
copyrights and trademarks. In using the Services, you agree not to upload, download,
distribute, or reproduce in any way any such proprietary materials without obtaining
BUKU's prior consent, except you may make limited copies of your booking documents
(and related documents) for boat rentals booked through this Website.
</p>  
      <h2>Unsolicited Ideas and Feedback</h2>
      <p className={css.text}>From time to time, users submit to us ideas or suggestions pertaining to our business,
such as ideas for new or improved products or technologies, website or tool
enhancements, processes, materials, marketing plans or new product names. We do
not solicit such ideas or suggestions and are under no obligation to review or consider
them. If you choose to submit any ideas, original creative artwork, suggestions or other
works ("submissions") in any form to us, then regardless of what you say, write or
provide to us in connection with your submissions, the following terms shall apply. The
sole purpose of this policy is to avoid potential misunderstandings or disputes in the
event that any part of our business, such as our products, websites, technologies or
marketing strategies, seem similar to any of your submissions.
</p>  
      <p className={css.text}>If you provide any submissions to us, you agree that: (1) we may use or redistribute any
such submission and its contents for any purpose and in any way without any
compensation to you; (2) there is no obligation for us to review any submission; and (3)
there is no obligation to keep any submission confidential.
</p>  
      <h2>No Spam, Spyware, or Spoofing</h2>
      <p className={css.text}>We and our Users do not tolerate spam. You may not use the Site to send spam or
otherwise send content that would violate the Terms.
</p>  
      <h2>Links to Third-Party Websites</h2>
      <p className={css.text}>The Services may contain links to other websites operated by parties other than BUKU
("Third-Party Websites"). BUKU's links to such other Third-Party Websites is for
informational purposes only and does not imply any endorsement of the products,
services or material on such Third-Party Websites or any association with their sponsors
or operators. You access any Third-Party Websites at your own risk. We are not
responsible in any way for such Third-Party Websites or resources. We will not have
any liability as a result of your use of such Third-Party Websites and resources, and
your use of such sites and resources will not be governed by these Terms.</p>
      <h2>Other Terms</h2>  
      <p className={css.text}>General Compliance with Laws. You shall comply with all applicable laws, statutes,
ordinances and regulations regarding your use of the Services.</p>  
      <p className={css.text}>Headings. Headings are for reference purposes only and in no way define, limit,
construe or describe the scope or extent of such section.</p>   
      <p className={css.text}>Assignment. This Agreement, the Terms, the Services and any modifications thereto
may not be assigned or in any way transferred by you without the prior written consent
of BUKU. BUKU may assign this Agreement, the Terms, and any information you
provide without your consent, including, without limitation, in connection with a sale of
its business, assets, a merger or other similar transaction.
</p>   
      <p className={css.text}>Governing Law. The terms of this Agreement shall be construed in accordance with the
substantive laws of the State of Maryland, United States of America, without giving
effect to the principles of conflict or choice of law of such state. The original form of this
Agreement has been written in English. The parties hereto waive any statute, law, or
regulation that might provide an alternative law or forum or might require this Agreement
to be written in any language other than English. This Agreement expressly excludes
the United Nations Convention on Contracts for the International Sale of Goods.</p>   
      <p className={css.text}>Choice of Forum and Venue. You hereby consent to the exclusive jurisdiction and venue
of courts in Baltimore City in the state of Maryland, U.S.A., in all disputes arising out of
the Terms or relating to the use of the Service.</p>   
      <p className={css.text}>No Responsibility for Acts of God. BUKU shall not be liable for any delay or failure to
perform in connection with any booking, Service or in the performance of any obligation
hereunder, if such delay or failure is due to or in any manner caused by acts of God,
rebellion, riots, hijacking, insurrection, civil commotion, strikes or labor disputes, fires,
floods, laws, regulations, acts, demands or and orders of any government or agency,
seizure of the boat under legal process, adverse weather conditions, inability to obtain
fuel, boat damage or loss, lack of essential parts or supplies, mechanical problems,
illness or incapacitation of crew members, denial of operating or docking approvals,
clearances or permits by governmental authority, or any other cause which is beyond
the control of BUKU. BUKU is hereby released by a User from any claim or demand for
any direct or consequential damages arising out of failure to perform as a result of a
force majeure event whether or not herein enumerated or other event referenced above.</p>   
      <p className={css.text}>Severability. If any provision of the Terms is found to be invalid, illegal or unenforceable
by any court having competent jurisdiction, the validity, legality and enforceability of the
remaining provisions will not in any way be affected or impaired thereby, and the
remaining valid, legal, and enforceable provisions will be interpreted, to the extent
possible, to achieve the purposes as originally expressed in the invalid, illegal, or
unenforceable provision.</p>   
      <p className={css.text}>Waiver. No waiver of any of the Terms shall be deemed a waiver of any other such
Terms set forth herein.</p>   
      <p className={css.text}>Entire Agreement. The Terms constitute the entire agreement between you and BUKU
with respect to the subject matter covered herein, including use of the Services and any
subject matter therein, and supersede all previous communications, representations,
understandings and agreements, either oral or written, between the parties with respect
to said subject matter.</p>   
      <p className={css.text}>Termination. BUKU may immediately issue a warning, temporarily suspend, indefinitely
suspend or terminate User's use of all or any portion of the Services if you breach the
Terms, or at any time or for any reason, at the sole discretion of BUKU.  </p>   
      <p className={css.text}>Non-Endorsement. BUKU does not recommend one Member over another and does not
provide advice to users regarding which Member to select for the rentals. All comments
or reviews posted by BUKU users, Renters, or Members on our Website do not
represent the views of BUKU in any way and are not endorsed by BUKU absent a
specific written statement to the contrary.</p>   
      <p className={css.text}>No Relationship. You agree that no joint venture, partnership, agency or employment
relationship exists between you and BUKU as a result of this Agreement or use of this
Website.
</p>   
      <p className={css.text}>Notice of Claim. You agree that all disputes arising out of the Terms or the use of
BUKU's Services shall be communicated to BUKU in writing within thirty (30) days from
the date of the incident causally related to the dispute. You hereby waive your right to
any claim against BUKU not communicated as provided by this Section.</p>   
      <p className={css.text}>Notice of Claim. You agree that all disputes arising out of the Terms or the use of
BUKU's Services shall be communicated to BUKU in writing within thirty (30) days from
the date of the incident causally related to the dispute. You hereby waive your right to
any claim against BUKU not communicated as provided by this Section.</p>   
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
