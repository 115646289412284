import { createSlice } from '@reduxjs/toolkit';

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    transaction: null,
    listing: null,
    error: null,
    isLoading: true
  },
  reducers: {
    setTransactionData(state, action) {
      state.transaction = action.payload.transaction;
      state.listing = action.payload.listing;
      state.error = null;
      state.isLoading = false;
    },
    transactionError(state, action) {
      state.error = action.payload.error;
      state.isLoading = false;
    }
  }
});

export const { setTransactionData, transactionError } = transactionSlice.actions;
export default transactionSlice.reducer;
