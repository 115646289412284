import React, { useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Field } from 'react-final-form'
import css from './BookingTimeForm.module.css';


const CounterField = (props) => {

    const { state, setState, label, name, min, max, disabled } = props;
    const minimum = min ?? 1;
    const maximum = max ?? 9999;

    const handleAdd = (onChange) => {
        if(disabled){
        }else{
            const newValue = state === maximum ? state : (state + 1);
            setState(newValue)
            onChange(newValue)
        }

    }

    const handleSubstract = (onChange) => {
        if(disabled){
        }else{
            const newValue = state <= minimum ? state : (state - 1);
            setState(newValue)
            onChange(newValue)
        }
    }

  
  const counter = ({ input: { onChange, value }, label, state, setState, ...rest }) => (
                    <div>
                        <div className={css.counterWrapper}>
                            <p className={css.counterLabel}>{label}</p>

                            <div className={css.controlsWrapper}>
                                <RemoveIcon 
                                className={css.control}
                                onClick={() => {return handleSubstract(onChange)}}
                                />
                                <p className={css.counterNumber}>{state}</p>
                                <AddIcon 
                                className={css.control}
                                onClick={() => {return handleAdd(onChange)}}
                                />
                            </div>
                            
                        </div>
                    </div>
                    )
  

  return(
        <Field
        name={name}
        label={label}
        component={counter}
        state={state}
        setState={setState}
    />
  )

}

export default CounterField