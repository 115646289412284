import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isTransactionsTransitionAlreadyReviewed } from '../../util/errors';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { FieldReviewRating, Form, PrimaryButton, FieldTextInput, FieldCurrencyInput } from '../../components';


import css from './RequestAdditionalPaymentForm.module.css';


import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const RequestAdditionalPaymentFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        form,
        invalid,
        requestAdditionalPaymentSent,
        requestAdditionalPaymentError,
        requestAdditionalPaymentInProgress,
      } = fieldRenderProps;

      const initialValues = {
        //requestAmount: new Money(0, 'USD'), // Set initial value for requestAmount
        requestAmount: 11,
      };

      //const [requestAmount, setRequestAmount] = React.useState(new Money(0, 'USD'));

      // const reviewRating = intl.formatMessage({ id: 'ReviewForm.reviewRatingLabel' });
      // const reviewRatingRequiredMessage = intl.formatMessage({
      //   id: 'ReviewForm.reviewRatingRequired',
      // });

      const reasonContentPlaceholderMessage = intl.formatMessage({
        id: 'RequestAdditionalPaymentForm.reasonContentPlaceholder',
      });
      const reasonContentRequiredMessage = intl.formatMessage({
        id: 'RequestAdditionalPaymentForm.reasonContentRequired',
      });

      const reasonContentLabel = intl.formatMessage({
        id: 'RequestAdditionalPaymentForm.reasonContentLabel',
      });

      // const errorMessage = isTransactionsTransitionAlreadyReviewed(sendReviewError) ? (
      //   <p className={css.error}>
      //     <FormattedMessage id="ReviewForm.reviewSubmitAlreadySent" />
      //   </p>
      // ) : (
      //   <p className={css.error}>
      //     <FormattedMessage id="ReviewForm.reviewSubmitFailed" />
      //   </p>
      // );
      // const errorArea = sendReviewError ? errorMessage : <p className={css.errorPlaceholder} />;

      const requestAdditionalPaymentSubmitMessage = intl.formatMessage({
        id: 'RequestAdditionalPaymentForm.requestAdditionalPaymentSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = requestAdditionalPaymentInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} 
          //initialValues={initialValues}
          onSubmit={handleSubmit}
          >
          {/* <FieldReviewRating
            className={css.reviewRating}
            id={formId ? `${formId}.starRating` : 'starRating'}
            name="reviewRating"
            label={reviewRating}
            validate={required(reviewRatingRequiredMessage)}
          /> */}

          {/* <FieldCurrencyInput 
            id={formId ? `${formId}.requestAmount` : 'requestAmount'}
            name="requestAmount"
            /> */}

          <FieldTextInput
           type="text"
            id={formId ? `${formId}.requestAmount` : 'requestAmount'}
            name="requestAmount"
            label="Request Amount"
            placeholder="Enter the amount you want to request"
            validate={required('Request amount is required')}
          />

          <FieldTextInput
            className={css.reviewContent}
            type="textarea"
            id={formId ? `${formId}.reasonContent` : 'reasonContent'}
            name="reasonContent"
            label={reasonContentLabel}
            placeholder={reasonContentPlaceholderMessage}
            validate={required(reasonContentRequiredMessage)}
          />

          {/* {errorArea} */}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={requestAdditionalPaymentSent}
          >
            {requestAdditionalPaymentSubmitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

RequestAdditionalPaymentFormComponent.defaultProps = { className: null, rootClassName: null, requestAdditionalPaymentError: null };

const { bool, func, string } = PropTypes;

RequestAdditionalPaymentFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  requestAdditionalPaymentSent: bool.isRequired,
  requestAdditionalPaymentError: propTypes.error,
  requestAdditionalPaymentInProgress: bool.isRequired,
};

const RequestAdditionalPaymentForm = compose(injectIntl)(RequestAdditionalPaymentFormComponent);
RequestAdditionalPaymentForm.displayName = 'RequestAdditionalPaymentForm';

export default RequestAdditionalPaymentForm;
